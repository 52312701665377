import styled from 'styled-components';
import { BreadCrumbProps, InputRoute } from './types';

const BreadCrumbContainer = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    flex-direction: row;
    padding: 7px 10px;
    background: #c7e6ef;
    border-bottom: solid coral 1px;
    border-top: solid coral 1px;

    @media (max-width: 450px) {
        padding: 5px;
      }
`;

const SeperatorImage = styled.img`
    width: 40px;
    height: 40px;
    cursor: pointer;

    margin-top: auto;
    margin-bottom: auto;

    @media (max-width: 450px) {
        width: 19px;
        height: 19px;
        
      }
`;

const SeperatorArrowImage = styled.img`
    width: 30px;
    height: 30px;
    margin : 7px 0px 0px 10px;

    margin-top: auto;
    margin-bottom: auto;

    @media (max-width: 450px) {
        width: 13px;
        height: 13px;
      }
`;


export { BreadCrumbContainer, SeperatorImage ,SeperatorArrowImage };