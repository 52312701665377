import React from "react";
import Grid from "../Grid";
import { FooterContainer, FooterRightsData, FooterPrivacyData } from "./styles";
import packageJson from "../../../package.json";

const currentYear = new Date().getFullYear();

// console.log('version',packageJson.version);

const Footer = () => {
  return (
    <FooterContainer>
      {/* <FooterRightsData data-testid = {'footerRightsData'}>@ EDF Energy {currentYear} | All Rights Reserved</FooterRightsData> */}
      <FooterPrivacyData data-testid={"footerPrivacyData"}>
        App Version {packageJson.version}
      </FooterPrivacyData>
    </FooterContainer>
  );
};

export default Footer;
