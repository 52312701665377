import React, { useState, useMemo, useEffect } from "react";
import Button from "../../components/button";
import { InventoryTr, InventoryTd, ReasonSelectList } from "./styles";
import ExclamatoryIcon from "../../app-assets/images/exclamatory_icon.png";

interface TableProps {
  row?: any;
  stausCodeUpdate?: (rowItem: any) => void;
  primaryAction(): void;
}

const TableRow = (props: TableProps) => {
  const { row = {}, stausCodeUpdate, primaryAction } = props;
  const [item, setItem] = useState(row);

  useEffect(() => {
    setItem(row);
  }, [row]);

  const onChangeStatus = (event: any) => {
    const value = event.target.value;
    const updatedItem: any = { ...item, statuscode: value };
    setItem(updatedItem);
  };

  const colorCode = useMemo(() => {
    const age = parseInt(item.asset_age);
    const asset_status = item.statuscode;
    console.log("age ***", age);

    let aging_msg_color = "#ff0000";
    if (asset_status == "ST003") {
      aging_msg_color = "#42424199";
    } else {
      if (age > 10) {
        aging_msg_color = "#4f9e30";
      } else if (age > 5) {
        aging_msg_color = "#f57f17";
      } else if (item.asset_age == "-1") {
        aging_msg_color = "#0061bb";
      } else {
        aging_msg_color = "#ff0000";
      }
    }

    return aging_msg_color;
  }, [item.asset_age, item.statuscode]);

  const handleAssetData = () => {
    primaryAction && primaryAction();
  };

  return (
    <InventoryTr bgColor={colorCode}>
      <InventoryTd
        id={"slNo"}
        dataLabel={"Serial No :"}
        style={{ cursor: "pointer" }}
        onClick={handleAssetData}
      >
        <div id={"assetNo"}>
          {item.reason_code === "R003" && item.reasond === "Recalled" && (
            <img src={ExclamatoryIcon} alt={item.reasond} />
          )}
          {item.assetserialnumber}
        </div>
      </InventoryTd>
      <InventoryTd dataLabel={"Tote Bag :"}>{item.totebarcode}</InventoryTd>
      <InventoryTd dataLabel={"Status :"}>
        <ReasonSelectList value={item.statuscode} onChange={onChangeStatus}>
          <option value="">Please Select Status</option>
          <option value="ST002">Collected</option>
          <option value="ST003">Scan Out</option>
        </ReasonSelectList>
      </InventoryTd>
      {/* <InventoryTd>{item.scantimestamp}</InventoryTd> */}
      <InventoryTd dataLabel={"SKU Code :"}>{item.skucode}</InventoryTd>
      <InventoryTd dataLabel={"SKU Description :"}>
        <span>{item.skudesc}</span>
      </InventoryTd>
      <InventoryTd dataLabel={""}>
        <Button
          type="button"
          label={"Update"}
          size="lg"
          testId={"ShowAllButton"}
          onClick={() => stausCodeUpdate && stausCodeUpdate(item)}
          bgColor="#424241"
          textColor="#ffffff"
          borderColor="#424241"
        />
      </InventoryTd>
    </InventoryTr>
  );
};

export default TableRow;
