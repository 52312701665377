import React, { useState, useEffect } from "react";
import Card from "../../components/Card";
import Container from "../../components/Container";
import BreadCrumb from "../../components/BreadCrumb";
import Button from "../../components/button";
import { InventoryTable, InventoryTh, InventoryTd, InventoryTr, InventoryThead, ReasonSelectList, InputTextdiv, InventoryTbody } from "./styles";
import { useSelector } from "react-redux";
import axios from "axios";
import { Constants } from "../../constants/constants";
import LoadingIndicators from "../../components/LoadingIndicators";
import TableRow from './TableRow';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useHistory } from 'react-router-dom';
import apiRequest from "../../apirequest";
// import { useIndexedDB } from "react-indexed-db";
import moment from "moment";
import { Insertofflinerequest } from "../offline/pendingrequest";
import { Refreshmyinventory, Fetchfflineinventory, Updateofflineinventory } from "../offline/myinventoryoffline";
import MyInventorySmallIcon from '../../app-assets/images/myinventory_small_icon.png';

toast.configure()



const MyInventory = () => {
  const breadCrumRoutes = [
    {
      name: "My Inventory",
      path: "/myinventory",
      icon: MyInventorySmallIcon,
      testId: "MyInventory_Data",

    },
  ];

  const userSession = useSelector(
    (state: any) => state.login.loginState.session
  );
  const jwtToken = userSession.idToken?.jwtToken;
  const location: any = useLocation();
  const preferred_username =
    userSession?.idToken?.payload?.preferred_username || "";
  const [inventoryDataList, setinventoryDataList] = useState<any[]>([]);
  const [skuCodeValue, setskuCodeValue] = useState(location?.state?.skuCode || '');
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  // My inventory offline changes
  // const { add, getAll, clear } = useIndexedDB("myinventory");
  const isOnline = useSelector((state: any) => state.login.isOnline);
  // ===

  const handleSkuCode = (event: any) => {
    const value = event.target.value;
    // console.log(value);
    setskuCodeValue(value);
  }

  const verifySKUCode = () => {
    // console.log('VerifySKUCODE', skuCodeValue);
    fetchInventoryData()
  }
  const showAllSKUCodes = () => {
    // console.log('ShowAllSKUCODE', skuCodeValue);
    setskuCodeValue('');
    fetchInventoryData(true);
  }

  const updateInventoryData = () => {
    const inventoryList = inventoryDataList;
  }

  useEffect(() => {
    fetchInventoryData();
  }, []);


  const fetchInventoryData = (searchAll: boolean = false) => {
    // offline changes
    // console.log(isOnline)
    setinventoryDataList([]);
    if (isOnline == false) {
      // console.log(skuCodeValue)
      Fetchfflineinventory(searchAll ? "" : skuCodeValue).then((invrecords: any[]) => {
        setinventoryDataList(invrecords)
      })

    } else {
      const config = {
        headers: {
          Authorization: jwtToken,
          "Content-Type": "application/json",
        },
      };
      const body = {
        operation: "fetchinventory",
        enggid: preferred_username,
        skucode: searchAll ? "" : skuCodeValue

      };
      setLoading(true);
      apiRequest(Constants.toteEndPoint, JSON.stringify(body))
        .then((response: any) => {
          setLoading(false);
          //console.log(response);
          const dataResponse = JSON.parse(response.data.response);
          // const { data } =  response;
          setinventoryDataList(dataResponse);
          // console.log(dataResponse);
          // offline changes
          if (skuCodeValue == "") {
            Refreshmyinventory(preferred_username)
          }
          // ==
        })
        .catch((error: any) => {
          Fetchfflineinventory(searchAll ? "" : skuCodeValue).then((invrecords: any[]) => {
            setLoading(false);
            setinventoryDataList(invrecords)
          }).catch(()=>{
            setLoading(false);
          })
          console.log("error");
        });
    }
  }

  const stausCodeUpdate = (listitem: any) => {
    let statuscode = listitem.statuscode;
    let assetserialnumber = listitem.assetserialnumber;
   

    //toast.success('Hello Geeks');
    const config = {
      headers: {
        Authorization: jwtToken,
        "Content-Type": "application/json",
      },
    };
    const body = {
      operation: "updateassetstatus",
      enggid: preferred_username,
      statuscode: statuscode,
      assetcode: assetserialnumber
    };

    let offlineassetdata = {
      assetcode: body.assetcode,
      operation: '',
      enggid: body.enggid,
      scan_time: new Date(),
      reasoncode: ''
    }
    if(body.statuscode=='ST002'){
      offlineassetdata.operation='collect_asset'
    }
    if(body.statuscode=='ST003'){
      offlineassetdata.operation='install_asset'
    }
    if (isOnline == false) {
      let offlinebody = {
        body: body,
        apipath: 'toteasset',
        httpmethod: 'PUT'
      }
      Insertofflinerequest(offlinebody).then((res) => {
        // console.log(res)
      })
      Updateofflineinventory(offlineassetdata).then((response:any)=>{
        const dataResponse = response.data;
        if (dataResponse.state == 'success') {
          toast.success(dataResponse.message);
          Updateofflineinventory(offlineassetdata)
        } else {
          toast.warning(dataResponse.message);
        }
      })
      
    } else {
      setLoading(true);
      apiRequest(Constants.toteEndPoint, JSON.stringify(body))
        .then((response: any) => {
          setLoading(false);
          // console.log(response);
          const dataResponse = response.data;

          if (dataResponse.state == 'success') {
            toast.success(dataResponse.message);
            Updateofflineinventory(offlineassetdata)
          } else {
            toast.warning(dataResponse.message);
          }
          // const { data } =  response;
          // toast.success('Hello Geeks');
          // console.log(dataResponse);

        })
        .catch((error: any) => {
          let offlinebody = {
            body: body,
            apipath: 'toteasset',
            httpmethod: 'PUT'
          }
          Insertofflinerequest(offlinebody).then((res) => {
            // console.log(res)
          })
          Updateofflineinventory(offlineassetdata).then((response:any)=>{
            setLoading(false);
            const dataResponse = response.data;
            if (dataResponse.state == 'success') {
              toast.success(dataResponse.message);
              Updateofflineinventory(offlineassetdata)
            } else {
              toast.warning(dataResponse.message);
            }
          })
          console.log("error");
        });
    }

  };

  const handleAssetData = (assetserialnumberdata: any) => {
    // console.log(assetserialnumberdata);
    history.push({
      pathname: './scanasset',
      state: {
        assetSerialNumber: assetserialnumberdata
      }
    })
  };



  return (
    <>
      <BreadCrumb inputRoutes={breadCrumRoutes} />
      <Container wide>
        <Card>
          <div id={"inventoryInput"} style={{ display: 'flex' }}>
            <InputTextdiv>
              <input
                type="text"
                value={skuCodeValue}
                placeholder="Search by SKU Code"
                style={{
                  color: "#0061bb",
                  border: "1px solid #0061bb",
                  width: "100%",
                  height: "100%",
                  // fontSize: '18px',
                  textAlign: 'center',
                  fontWeight: 'bold'
                }}
                onChange={handleSkuCode}
              />
            </InputTextdiv>
            <div>
              <Button
                type="button"
                label={"Search"}
                size="lg"
                testId={"SearchButton"}
                onClick={verifySKUCode}
                bgColor="#0061bb"
                textColor="#ffffff"
                borderColor="#0061bb"
              />{" "}
              &nbsp;
              <Button
                type="button"
                label={"Show All"}
                size="lg"
                testId={"ShowAllButton"}
                onClick={showAllSKUCodes}
                bgColor="#0061bb"
                textColor="#ffffff"
                borderColor="#0061bb"
              />
            </div>
          </div>
          <div style={{ marginTop: '20px' }}>
            {isLoading && (
              <div style={{ position: "absolute", left: 0, right: 0 }}>
                <LoadingIndicators
                  size="lg"
                  position="center"
                  colour="gray"
                  type="circle"
                />
              </div>
            )}
            <div style={{overflowX:"scroll"}}>
            <InventoryTable>
              <InventoryThead>
                <InventoryTr>
                  <InventoryTh>Serial No</InventoryTh>
                  <InventoryTh>Tote Bag</InventoryTh>
                  <InventoryTh>Status</InventoryTh>
                  {/* <InventoryTh>Shipped Date</InventoryTh> */}
                  <InventoryTh>SKU Code</InventoryTh>
                  <InventoryTh>SKU Description</InventoryTh>
                  <InventoryTh>Update</InventoryTh>
                </InventoryTr>
              </InventoryThead>
              <InventoryTbody>
                {inventoryDataList && Array.isArray(inventoryDataList) && inventoryDataList.map((item: any, index: number) => {
                  return <TableRow key={item.assetserialnumber} row={item} stausCodeUpdate={stausCodeUpdate} primaryAction={() => handleAssetData(item.assetserialnumber)} />
                })}
              </InventoryTbody>
            </InventoryTable>
            </div>
          </div>
        </Card>
      </Container>
    </>
  );
};

export default MyInventory;
