import React, { useState, useEffect } from "react";
import Card from "../../components/Card";
import Container from "../../components/Container";
import BreadCrumb from "../../components/BreadCrumb";
import Button from "../../components/button";
import {
  InventoryTable,
  InventoryTh,
  InventoryTd,
  InventoryTr,
  InventoryThead,
  InputTextdiv,
  InventoryTrhead,
  InventoryTbody,
  OfflineMsgSpan,
} from "./styles";
import { useSelector } from "react-redux";
import axios from "axios";
import { Constants } from "../../constants/constants";
import LoadingIndicators from "../../components/LoadingIndicators";
import apiRequest from "../../apirequest";
import SearchSmallIcon from "../../app-assets/images/search_small_icon.png";

const Search = () => {
  const breadCrumRoutes = [
    {
      name: "Search",
      path: "/search",
      icon: SearchSmallIcon,
      testId: "Search_Data",
    },
  ];

  const userSession = useSelector(
    (state: any) => state.login.loginState.session
  );
  const jwtToken = userSession.idToken?.jwtToken;
  const preferred_username =
    userSession?.idToken?.payload?.preferred_username || "";
  const [searchDataList, setsearchDataList] = useState([]);
  const [skuCodeValue, setskuCodeValue] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [offlineMsgDisplay, setOfflineMsgDisplay] = useState(false);
  const networkState = useSelector((state: any) => state.login.isOnline);
  const networkStatusdescription = networkState
    ? ""
    : "In Offline mode Search data will not be rendered";

  const handleSkuCode = (event: any) => {
    const value = event.target.value;
    console.log(value);
    setskuCodeValue(value);
  };

  const verifySKUCode = () => {
    console.log("VerifySKUCODE", skuCodeValue);
    if (skuCodeValue) {
      fetchSearchData();
    }
  };
  const showAllSKUCodes = () => {
    console.log("ShowAllSKUCODE", skuCodeValue);
    setskuCodeValue("");
    setsearchDataList([]);
    setOfflineMsgDisplay(false);
    // fetchSearchData(true);
  };

  useEffect(() => {
    // fetchSearchData();
  }, []);

  const fetchSearchData = (searchAll: boolean = false) => {
    if (!networkState) {
      setLoading(false);
      setOfflineMsgDisplay(true);
    } else {
      const body = {
        operation: "skucodesearch",
        enggid: preferred_username,
        skucode: skuCodeValue,
      };
      setOfflineMsgDisplay(false);
      setLoading(true);
      apiRequest(Constants.toteEndPoint, JSON.stringify(body))
        .then((response: any) => {
          setLoading(false);
          console.log(response);
          const dataResponse = JSON.parse(response.data.response);
          // const { data } =  response;
          setsearchDataList(dataResponse);
          console.log(dataResponse);
        })
        .catch((error: any) => {
          setLoading(false);
          console.log("error", error);
        });
    }
  };

  return (
    <>
      <BreadCrumb inputRoutes={breadCrumRoutes} />
      <Container wide>
        <Card>
          <div id={"inventoryInput"} style={{ display: "flex" }}>
            <span style={{ color: "Red", fontSize: "35px" }}>*</span>
            <InputTextdiv>
              <input
                type="text"
                value={skuCodeValue}
                placeholder="Search By SKU Code"
                style={{
                  color: "#0061bb",
                  border: "1px solid #0061bb",
                  width: "100%",
                  height: "100%",
                  // fontSize: "18px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
                onChange={handleSkuCode}
                data-testid={"searchInput"}
              />
            </InputTextdiv>
            <div>
              <Button
                type="button"
                label={"Search"}
                size="lg"
                testId={"SearchButton"}
                disabled={!(skuCodeValue.length > 0)}
                onClick={verifySKUCode}
                bgColor="#0061bb"
                textColor="#ffffff"
                borderColor="#0061bb"
              />{" "}
              &nbsp;
              <Button
                type="button"
                label={"Clear"}
                size="lg"
                testId={"ShowAllButton"}
                onClick={showAllSKUCodes}
                bgColor="#0061bb"
                textColor="#ffffff"
                borderColor="#0061bb"
              />
            </div>
          </div>
          <div style={{ marginTop: "20px" }}>
            {isLoading && (
              <div style={{ position: "absolute", left: 0, right: 0 }}>
                <LoadingIndicators
                  size="lg"
                  position="center"
                  colour="gray"
                  type="circle"
                />
              </div>
            )}
            <div style={{ overflowX: "scroll" }}>
              <InventoryTable>
                <InventoryThead>
                  <InventoryTrhead>
                    <InventoryTh>Engineer's Name</InventoryTh>
                    <InventoryTh>SKU Code</InventoryTh>
                    <InventoryTh>No of Assets</InventoryTh>
                    <InventoryTh>Contact Number</InventoryTh>
                  </InventoryTrhead>
                </InventoryThead>
                <InventoryTbody>
                  {searchDataList &&
                    Array.isArray(searchDataList) &&
                    searchDataList.map((item: any, index: number) => {
                      return (
                        <InventoryTr key={item.skucode}>
                          <InventoryTd>{item.engineername}</InventoryTd>
                          <InventoryTd>{item.skucode}</InventoryTd>
                          <InventoryTd>{item.assetcount}</InventoryTd>
                          <InventoryTd>{item.contactnumber}</InventoryTd>
                        </InventoryTr>
                      );
                    })}
                </InventoryTbody>
              </InventoryTable>
            </div>

            {offlineMsgDisplay && (
              <OfflineMsgSpan data-testid={"offlinemsg"}>
                {networkStatusdescription ? networkStatusdescription : ""}{" "}
              </OfflineMsgSpan>
            )}
          </div>
        </Card>
      </Container>
    </>
  );
};

export default Search;
