import React from 'react';
import { ContainerWrapper } from './style';
import { ContainerProps } from './types';
import Wallace from '../Wallace';

const Container:React.FC<ContainerProps> = (props) => {
	const { wide, children } = props;
	return(
		<Wallace>
			<ContainerWrapper wide={wide}>
				{children}
			</ContainerWrapper>
		</Wallace>
	)
};

export default Container;
