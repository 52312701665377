// import { useIndexedDB } from "react-indexed-db";
import apirequest from '../../apirequest';
import config from '../../config';
import moment from "moment";
import { db } from '../../db';


export const RefreshReasoncode = (reasoncodelist: any[]) => {
    db.reasoncode.clear().then(() => {
        reasoncodelist.forEach((item) => {
            let code = item.reason
            let description = item.description
            let timemodified = moment().format('YYYY-MM-DD HH:mm:ss')
            let dxdata = { code, description, timemodified }
            db.reasoncode.add(dxdata)
        })
    })
}

export const Fetchofflinereasoncode = () => {
    let response = new Promise(async (resolve, reject) => {
        let reasondata: any[] = [];
        db.reasoncode.each(record => {
            let rec = {
                reason: record.code,
                description: record.description
            }
            reasondata.push(rec)
        }).then(() => {
            console.log(reasondata)
            resolve(reasondata)
        }).catch((err) => {
            reject([])
        });
    })
    return response;
}


