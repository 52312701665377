import React from 'react';
import { Link } from 'react-router-dom';
import Wallace from '../Wallace';
import { BreadCrumbContainer, SeperatorImage , SeperatorArrowImage } from './styles';
import { BreadCrumbProps, InputRoute } from './types';
import arrowIcon from '../../app-assets/images/arrowicon.png';
import homeIcon from '../../app-assets/images/homeicon.png';
import { useHistory } from 'react-router-dom'

const BreadCrumb:React.FC<BreadCrumbProps> = (props) => {
    const { inputRoutes, callBackDataCheck } = props;
    const history = useHistory();
    const onHomePress = () => {
        if (callBackDataCheck && callBackDataCheck()) {
            return
        }
        history.push("/");
    }
    return(
        <Wallace>
            <BreadCrumbContainer>
                <SeperatorImage onClick={onHomePress} data-testid={"breadcrum_homeIcon"} src={homeIcon} />
                <SeperatorArrowImage src={arrowIcon} data-testid={"breadcrum_arrowIcon"}/>
                
                {
                    inputRoutes.map((route: InputRoute, index: number) => (
                        <div key={`breadcrumitem_${route.name}_${index}`} style={{ marginLeft: '10px', alignItems: 'center', display: 'flex' }}>
                            {route.icon && <SeperatorImage src={route.icon} />}&nbsp;
                            {route.name && <Link  data-testid={route.testId} style={{ textDecoration: 'none', color: '#005bbb',fontSize:'18px',fontWeight:'bold' }} to={route.path}>{route.name}</Link>}
                            <SeperatorArrowImage src={arrowIcon}  data-testid={"breadcrum_arrowIcon"}/>
                        </div>
                    ))
                }
            </BreadCrumbContainer>
        </Wallace>
    )
}

export default BreadCrumb;