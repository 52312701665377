import React from 'react';

import Circle from './styles';
import edfLoadingGif from "../../app-assets/images/edf_loader.gif";

export type Sizes = 'lg' | 'md' | 'sm';
export type Positions = 'left' | 'center' | 'right';

export type Colours = 'gray' | 'blue' | 'green' | 'red';

export type IndicatorOptions = {
  size: Sizes;
  colour: Colours;
  position: Positions;
};

interface LoadingIndicatorProps extends IndicatorOptions {
  type: 'circle';
}

const LoadingIndicators: React.FC<LoadingIndicatorProps> = (props) => {
  const {
    type, size, colour, position,
  } = props;
  if (type === 'circle') {
    return <Circle size={size} colour={colour} position={position} />;
    // return <img src={edfLoadingGif}></img>
  }
  return <div />;
};

export default LoadingIndicators;
