import styled from 'styled-components';
import { CardProps } from './types';

const CardContainer = styled.div<CardProps>`
    // box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 40px;

    @media (max-width: 450px){
        padding: 0;

        #inventoryInput {
            // background-color: red;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 25px;
        }
    }
`;

export { CardContainer };