import styled from "styled-components";

const InputTextdiv = styled.div`
  padding: 5px;
  width: 100%;
  display: flex;
  justify-content: center;
  //   border: 1px solid red;
  align-items: center;
  label {
    width: 25%;
    font-weight: bold;
    text-align: left;
  }
`;
const InputFieldTag = styled.input`
  margin-left: auto;
  width: 75%;
  height: 40px;
  background-color: #8080801c;
  border: 1px solid #8080801c;
  &:disabled {
    background: #8080801c;
    cursor: not-allowed;
    pointer-events: all !important;
  }
`;

const InputFieldCheckBox = styled.input`
  width: 30px;
  height: 30px;
  border: 1px solid #8080801c;
`;

const Inputcheckboxdiv = styled.div`
  padding: 7px;
  label {
    // display: block;
    font-weight: bold;
    text-align: initial;
    margin-right: 50px;
  }
`;

const SelectBox=styled.select`{
  width:100px;
  height:30px;
}`

export { InputTextdiv, InputFieldTag, InputFieldCheckBox, Inputcheckboxdiv ,SelectBox};