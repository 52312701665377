import styled from "styled-components";

const HeaderContainer = styled.header`
  background: #ff4500;
  margin: 0;
`;

const NavHeader = styled.div`
  color: white;
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 450px) {
    margin: 1px;
  }
`;

const NavEmail = styled.div`
  // font-size: 17x;

  @media (max-width: 450px) {
    font-size: 13px;
  }
`;

const NavLastLogin = styled.div`
  // font-size: 15px;

  @media (max-width: 450px) {
    font-size: 11px;
  }
`;

export default HeaderContainer;

export { NavHeader, NavLastLogin, NavEmail };
