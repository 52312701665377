import React, { useState, useRef, useEffect } from "react";
import Card from "../../components/Card";
import Container from "../../components/Container";
import BreadCrumb from "../../components/BreadCrumb";
import Button from "../../components/button";
import { CSVLink } from "react-csv";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  InventoryTable,
  InventoryTh,
  SplitHeader,
  InventoryTd,
  InventoryTr,
  InventoryThead,
  InventoryTbody,
  InventoryTrhead,
  InputFieldsDiv,
  DatePickerStyled,
  TextDivTag,
  AssetStatusSelectList,
  ProgressBar
} from "./styles";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "../../app-assets/images/calendar_icon.png";
import moment from "moment";
import DateInput from "../../components/DateInput/DateInput";
import { useSelector } from "react-redux";
import { Constants } from "../../constants/constants";
import LoadingIndicators from "../../components/LoadingIndicators";
import apiRequest from "../../apirequest";
import ReactPaginate from "react-paginate";
import "./pagination.css";
import ReportsSmallIcon from "../../app-assets/images/reports_small_icon.png";
import StringEncoding from "@zxing/library/esm/core/util/StringEncoding";
import { InputFieldTag } from "../createuser/styles";

toast.configure();

const Reports = () => {
  const breadCrumRoutes = [
    {
      name: "Reports",
      path: "/reports",
      icon: ReportsSmallIcon,
      testId: "Reports_Data",
    },
  ];
  const [startDate, setStartDate] = useState<any>(new Date());
  const [endDate, setEndDate] = useState<any>(new Date());
  const [assetStatusValue, setAssetStatusValue] = useState("");
  const startDateRef: any = useRef(null);
  const endDateRef: any = useRef(null);
  const userSession = useSelector(
    (state: any) => state.login.loginState.session
  );
  const jwtToken = userSession.idToken?.jwtToken;
  const preferred_username =
    userSession?.idToken?.payload?.preferred_username || "";
  const [isLoading, setLoading] = useState(false);
  const [serialNumber, setSerialNumber] = useState("");
  const [skuCode, setSkuCode] = useState("");
  const [Uin, setUin] = useState("");
  const [reportsDataList, setReportsDataList] = useState([]);
  const [assetStatusDataList, setAssetStatusDataList] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [tableData, setTableData] = useState([]);
  const itemsPerPage = 10;
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [downloadState, setDownloadState] = useState('Download');
  const [companyName,setCompanyName]=useState('');

  const handleSubmit = () => { };
  const handleDownload = () => { };
  const onChangeStartDate = (date: any) => {
    if (moment(date).isAfter(endDate)) {
      setEndDate(date);
    }
    setStartDate(date);
  };

  const handleAssetStatus = (event: any) => {
    const value = event.target.value;
    setAssetStatusValue(value);
    // console.log("ReasonCode", value);
  };

  const handleSkuCode = (event: any) => {
    const value = event.target.value;
    setSkuCode(value);
    // console.log("SKU Code", value);
  };

  const handleUin = (event: any) => {
    const value = event.target.value;
    setUin(value);
    // console.log("UIN", value);
  };

  const handleSerialNumber = (event: any) => {
    const value = event.target.value;
    setSerialNumber(value);
    // console.log("SerialNumber", value);
  };

  const handleCompanyNameSearch=(event:any)=>{
    const value=event.target.value;
    setCompanyName(value)
  }

  const formatDate = (date: any) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const changeReportStatus = (operation: string) => {
    // on clicking "Submit" button displays tabular report based on filters.

    setReportsDataList([]);
    const config = {
      headers: {
        Authorization: jwtToken,
        "Content-Type": "application/json",
      },
    };

    setLoading(true);

    const body_filter = {
      from_date: formatDate(startDate),
      to_date: formatDate(endDate),
      status: assetStatusValue,
      assetcode: serialNumber,
      skucode: skuCode,
      uin: Uin,
      companyname:companyName,
      operation: operation,
      enggid: preferred_username,
      scan_time: new Date(),
    };

    let data_array: any = [];
    let totalRecordCount = 0;

    const getRecordChunks = (batchEndAsset: string) =>{
      // fetches records from backend in smaller batches, invokes multiple API calls recursively. e.g. 3000 records (limit) at a time.

      const body={...body_filter, limit:5000, end_asset:batchEndAsset};

      apiRequest(Constants.toteEndPoint, JSON.stringify(body))
      .then((response: any) => {
        // console.log(response);
        let dataResponse = JSON.parse(response.data.response);
        if (batchEndAsset == 'First'){
          totalRecordCount = JSON.parse(response.data.totalRecordCount);
          if (totalRecordCount>30000){
            toast.warning(`Request Initiated for Large Dataset`);
          }
          setTotalRecords(totalRecordCount);
        }

        if (dataResponse.includes('No Record Found')){
          setItemOffset(0);
          // need to sort the data_array wrt date here..
          const fetchedRecordCount = data_array.length;
          data_array=sortDataArrayByDate(data_array);
        
          if (fetchedRecordCount === 0){
            const successMessage = `No Records Found`;
            toast.info(successMessage);
          }
          else{
            const successMessage = `Done. ${fetchedRecordCount} Record${fetchedRecordCount>1?'s':''} Fetched`;
            toast.success(successMessage);
          }
          setReportsDataList(data_array);
          setLoading(false);
          setTotalRecords(0);
          setDownloadProgress(0);
          setDownloadState('Download');
        } else {
          if (typeof(dataResponse) == 'object' && dataResponse.length > 0){
            // console.log('record count for set: ', dataResponse.length);
            data_array.push(...dataResponse);
            let progress = Math.floor(((data_array.length * 100) / totalRecordCount ));
            // console.log(totalRecordCount, data_array.length, progress);
            setDownloadProgress(progress);
            if (progress === 100){
              setDownloadState('Sort');
            }
            const newbatchEndAsset=dataResponse[dataResponse.length-1].assetserialnumber;
            getRecordChunks(newbatchEndAsset);
          }
        }
      })
      .catch((error: any) => {
      setLoading(false);
      const errorMessage = `Error: ${error.message}`;
      toast.error(errorMessage);
      });
    }

    getRecordChunks('First');

  };

  const sortDataArrayByDate = (d_array: []) =>{
    // this will sort the array w.r.t the date value of the objects 
    d_array.sort((a: any, b:any)=>{
      // will return a value which is either negative, positive or zero.
      return new Date(b.status_changed_date).getTime() - new Date(a.status_changed_date).getTime();
    });
    return d_array;
  }

  const headers = [
    { label: "Asset Serial Number", key: "assetserialnumber" },
    { label: "Assigned To - LanID", key: "assigned_operative_lanid" },
    { label: "Assigned To - UIN", key: "assigned_operative_uin" },
    { label: "Status", key: "asset_status" },
    { label: "Reason Code", key: "reason" },
    { label: "Tote ID", key: "totebarcode" },
    { label: "SKU code", key: "skucode" },
    { label: "SKU Description", key: "skudesc" },
    { label: "Company Name", key: 'companyname'},
    { label: "Modified Date", key: "status_changed_date" },
    { label: "Modified By", key: "modifiedby" },
    { label: "Asset Age", key: "asset_age" },

  ];

  useEffect(() => {
    fetchAssetStatusCodes();
  }, []);

  const fetchAssetStatusCodes = () => {
    const config = {
      headers: {
        Authorization: jwtToken,
        "Content-Type": "application/json",
      },
    };
    const body = {
      operation: "statuscode",
      enggid: preferred_username,
    };
    apiRequest(Constants.toteEndPoint, JSON.stringify(body))
      .then((response: any) => {
        const dataResponse = JSON.parse(response.data.response);
        // const { data } =  response;
        setAssetStatusDataList(dataResponse);
        setItemOffset(0);
        // console.log(dataResponse);
      })
      .catch((error: any) => {
        const errorMessage = `Error: ${error.message}`;
        toast.error(errorMessage);
      });
  };

  useEffect(() => {
    getPageData();
  }, [itemOffset, reportsDataList]);

  const getPageData = () => {
    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setTableData(reportsDataList.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(reportsDataList.length / itemsPerPage));
  };

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % reportsDataList.length;
    setItemOffset(newOffset);
  };

  const resetPage = () => {
    // console.log("Page reset clicked!");
    setReportsDataList([]);
    setSerialNumber("");
    setSkuCode("");
    setUin("");
    setStartDate(new Date());
    setEndDate(new Date());
    setAssetStatusValue("");
    setCompanyName("");
  }

  return (
    <>
      <BreadCrumb inputRoutes={breadCrumRoutes} />
      <Container wide>
        <Card>
          <div style={{ display: "flex" }}>
            <InputFieldsDiv>
              <TextDivTag>From Date</TextDivTag>
            </InputFieldsDiv>
            <InputFieldsDiv>
              <div style={{ display: "flex" }}>
                <DatePickerStyled>
                  <DatePicker
                    ref={startDateRef}
                    selected={startDate}
                    onChange={(date) => onChangeStartDate(date)}
                    data-testid={"start-date-picker"}
                    calendarClassName=""
                  //   customInput={<DateInput/>}
                  ></DatePicker>
                </DatePickerStyled>
                <div
                  style={{ position: "relative", top: "3px", right: "32px" }}
                >
                  <img
                    src={CalendarIcon}
                    height="40px"
                    width="30px"
                    onClick={() =>
                      startDateRef && startDateRef?.current?.setOpen(true)
                    }
                  />
                </div>
              </div>
            </InputFieldsDiv>
            <InputFieldsDiv>
              <TextDivTag>To Date</TextDivTag>
            </InputFieldsDiv>
            <InputFieldsDiv>
              <div style={{ display: "flex" }}>
                <DatePickerStyled>
                  <DatePicker
                    ref={endDateRef}
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    minDate={startDate}
                  />
                </DatePickerStyled>
                <div
                  style={{ position: "relative", top: "3px", right: "32px" }}
                >
                  <img
                    src={CalendarIcon}
                    height="40px"
                    width="30px"
                    onClick={() => endDateRef?.current?.setOpen(true)}
                  />
                </div>
              </div>
            </InputFieldsDiv>
            <InputFieldsDiv>
              <input
                type="text"
                placeholder="Enter Serial No"
                value={serialNumber}
                onChange={handleSerialNumber}
                data-testid={"serialnumberinput"}
              />
            </InputFieldsDiv>
            <InputFieldsDiv>
              <input
                style={{width:'110px'}}
                type="text"
                placeholder="Enter SKU Code"
                value={skuCode}
                onChange={handleSkuCode}
                data-testid={"skucodeinput"}

              />
            </InputFieldsDiv>
            <InputFieldsDiv>
              <input
                type="text"
                placeholder="Enter UIN"
                value={Uin}
                onChange={handleUin}
                data-testid={"uininput"}
              />
            </InputFieldsDiv>
            <InputFieldsDiv>
              <input type="text"
              style={{width:'140px'}}
              placeholder='Enter Company Name' 
              value={companyName}
              onChange={handleCompanyNameSearch}
              data-tested={'companynameinput'}
              />
              
            </InputFieldsDiv>
            <InputFieldsDiv>
              <AssetStatusSelectList
                onChange={handleAssetStatus}
                value={assetStatusValue}
              >    
                <option value="" data-testid={"assetStatusId"}>All Statuses</option>
                {assetStatusDataList &&
                  Array.isArray(assetStatusDataList) &&
                  assetStatusDataList.map((item: any, index: number) => {
                    return (
                      <option
                        key={`${item.statuscode}-${index}`}
                        value={item.statuscode}
                      >
                        {item.description}
                      </option>
                    );
                  })}
              </AssetStatusSelectList>
            </InputFieldsDiv>
            <InputFieldsDiv>
              <Button
                type="button"
                label={"Submit"}
                size="sm"
                testId={"SubmitButton"}
                onClick={() => changeReportStatus("assetreport")}
                bgColor="#0e7d11"
              />
            </InputFieldsDiv>
            <InputFieldsDiv>
              <CSVLink
                filename={`AssetReports_${Date.now()}.csv`}
                headers={headers}
                data={reportsDataList}
                style={{ textDecoration: "none" }}
              >
                <Button
                  type="button"
                  label={"Download"}
                  size="sm"
                  testId={"DownloadButton"}
                  onClick={() => { }} // do nothing, CSVLink (wrapper component) will handle the download
                  bgColor="#0e7d11"
                />
              </CSVLink>
            </InputFieldsDiv>
            <InputFieldsDiv>
              <Button
                type="button"
                label={"Reset"}
                size="sm"
                testId={"ResetButton"}
                onClick={resetPage}
                bgColor="#0e7d11"
              />
            </InputFieldsDiv>
          </div>
          <div style={{ marginTop: "20px" }}>
            {isLoading && (
              <div style={{ position: "absolute", left: 0, right: 0 }}>
                <LoadingIndicators
                  size="lg"
                  position="center"
                  colour="gray"
                  type="circle"
                />
                <label>
                  {downloadState == 'Download' && (<p>Fetching Report ({downloadProgress}%)</p>)}
                  {downloadState == 'Sort' && (<p>Processing..</p>)}
                  <ProgressBar value={downloadProgress} max="100"></ProgressBar>
                  {totalRecords>0 && (<p>Total Records: {totalRecords}</p>)}
                </label>
              </div>
            )}
            <InventoryTable>
              <InventoryThead>
                <InventoryTrhead>
                  {/* <InventoryTh>Reporting Date</InventoryTh> */}
                  <InventoryTh>Asset SL. Number</InventoryTh>
                  <SplitHeader>Assigned To</SplitHeader>
                  <InventoryTh>Status</InventoryTh>
                  <InventoryTh>Reason Code</InventoryTh>
                  <InventoryTh>Tote ID</InventoryTh>
                  <InventoryTh>SKU code</InventoryTh>
                  <InventoryTh>SKU Description</InventoryTh>
                  <InventoryTh>Company Name</InventoryTh>
                  <InventoryTh>Modified Date</InventoryTh>
                  <InventoryTh>Modified By</InventoryTh>
                  <InventoryTh>Asset Age</InventoryTh>
                </InventoryTrhead>
                <InventoryTrhead>
                  <InventoryTh>{"LanId"}</InventoryTh>
                  <InventoryTh>{"UIN"}</InventoryTh>
                </InventoryTrhead>
              </InventoryThead>
              <InventoryTbody>
                {tableData &&
                  Array.isArray(tableData) &&
                  tableData.map((item: any, index: number) => {
                    return (
                      <InventoryTr key={item.assetserialnumber}>
                        {/* <InventoryTd>{item.report_date}</InventoryTd> */}
                        <InventoryTd>{item.assetserialnumber}</InventoryTd>
                        <InventoryTd>{item.assigned_operative_lanid}</InventoryTd>
                        <InventoryTd>{item.assigned_operative_uin}</InventoryTd>
                        <InventoryTd>{item.asset_status}</InventoryTd>
                        <InventoryTd>{item.reason}</InventoryTd>
                        <InventoryTd>{item.totebarcode}</InventoryTd>
                        <InventoryTd>{item.skucode}</InventoryTd>
                        <InventoryTd>{item.skudesc}</InventoryTd>
                        <InventoryTd>{item.companyname}</InventoryTd>
                        <InventoryTd>{item.status_changed_date}</InventoryTd>
                        <InventoryTd>{item.modifiedby}</InventoryTd>
                        <InventoryTd>
                          {item.asset_age == "-1" ? "NA" : item.asset_age}
                        </InventoryTd>
                      </InventoryTr>
                    );
                  })}
              </InventoryTbody>
            </InventoryTable>

            {tableData && Array.isArray(reportsDataList) && pageCount > 1 && (
              <div className="report_pagi">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="Next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={2}
                  pageCount={pageCount}
                  previousLabel="< Previous"
                  marginPagesDisplayed={2}
                />
              </div>
            )}
          </div>
        </Card>
      </Container>
    </>
  );
};

export default Reports;