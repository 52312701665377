import React from 'react';
import Card from '../../components/Card';
import Container from '../../components/Container';
import HomeScreenIcon from '../../components/HomeScreenIcons';
import Grid from '../../components/Grid';
import BreadCrumb from '../../components/BreadCrumb';
import { useHistory } from 'react-router-dom';
import reportsIcon from '../../app-assets/images/reportsicon.png';
import userDetailsIcon from '../../app-assets/images/userdetailsicon.png';


const BackOfficeHome = () => { 
    const history = useHistory();
	const handleUserDetails = () => {
		history.push('/userdetails');
	}
	const handleReports = () => {
		history.push('/reports');
	}
    return (
		<>
			<BreadCrumb inputRoutes={[]} />
			<Container>
				<div style={{ marginTop: '80px' }}>
				<Card>
					<Grid row>
                    <Grid column md={3} sm={12} children={undefined}></Grid>
						<Grid column md={3} sm={12}>
							<HomeScreenIcon
								imageName={reportsIcon}
								name={"Reports"}
								action={handleReports}
								bgColor = "#333f50"
								contentTestId = {'reportsText'}
							/>
						</Grid>
						<Grid column md={3} sm={12}>
							<HomeScreenIcon
								imageName={userDetailsIcon}
								name={"User Details"}
								action={handleUserDetails}
								bgColor = "#5b9bd5"
								contentTestId = {'userDetailsText'}
							/>
						</Grid>
						<Grid column md={3} sm={12} children={undefined}></Grid>
					</Grid>
				</Card>
				</div>
			</Container>
		</>
	);

}

export default BackOfficeHome;