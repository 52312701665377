import styled from 'styled-components';
import type { GridJustify, GridProps, GridSizes } from './types';

const justiftOfItems = (justify?: GridJustify) => {
  switch (justify) {
    case 'flex-start':
      return 'justify-content: flex-start;';
    case 'center':
      return 'justify-content: center;';
    case 'flex-end':
      return 'justify-content: flex-end;';
    case 'space-between':
      return 'justify-content: space-between;';
    case 'space-around':
      return 'justify-content: space-around;';
    case 'space-evenly':
      return 'justify-content: space-evenly;';
    default:
      return '';
  }
};

interface Sizes {
  sm: {
    // eslint-disable-next-line no-unused-vars
    [key in GridSizes]: string;
  };
  md: {
    // eslint-disable-next-line no-unused-vars
    [key in GridSizes]: string;
  };
  lg: {
    // eslint-disable-next-line no-unused-vars
    [key in GridSizes]: string;
  };
}

const sizes: Sizes = {
  sm: {
    1: `
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    `,
    2: `
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    `,
    3: `
      flex: 0 0 25%;
      max-width: 25%;
    `,
    4: `
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    `,
    5: `
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    `,
    6: `
      flex: 0 0 50%;
      max-width: 50%;
    `,
    7: `
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    `,
    8: `
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
      `,
    9: `
      flex: 0 0 75%;
      max-width: 75%;
      `,
    10: `
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
      `,
    11: `
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
   `,
    12: `
      flex: 0 0 100%;
      max-width: 100%;
    `,
  },
  md: {
    1: `
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    `,
    2: `
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    `,
    3: `
      flex: 0 0 25%;
      max-width: 25%;
    `,
    4: `
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    `,
    5: `
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    `,
    6: `
      flex: 0 0 50%;
      max-width: 50%;
    `,
    7: `
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    `,
    8: `
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
      `,
    9: `
      flex: 0 0 75%;
      max-width: 75%;
      `,
    10: `
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
      `,
    11: `
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
   `,
    12: `
      flex: 0 0 100%;
      max-width: 100%;
    `,
  },
  lg: {
    1: `
      flex: 0 0 8.33333%;
      max-width: 8.33333%;
    `,
    2: `
      flex: 0 0 16.66667%;
      max-width: 16.66667%;
    `,
    3: `
      flex: 0 0 25%;
      max-width: 25%;
    `,
    4: `
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    `,
    5: `
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    `,
    6: `
      flex: 0 0 50%;
      max-width: 50%;
    `,
    7: `
      flex: 0 0 58.33333%;
      max-width: 58.33333%;
    `,
    8: `
      flex: 0 0 66.66667%;
      max-width: 66.66667%;
      `,
    9: `
      flex: 0 0 75%;
      max-width: 75%;
      `,
    10: `
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
      `,
    11: `
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
   `,
    12: `
      flex: 0 0 100%;
      max-width: 100%;
    `,
  },
};

const Row = styled.div<GridProps>`

  display: flex;
  flex-flow: row wrap;
  margin-right: auto;
  margin-left: auto;

  ${({ expanded }) =>
    // eslint-disable-next-line operator-linebreak
    expanded &&
    `
    max-width: none;
  
  `}

  ${({ gridHeight }) =>
    gridHeight &&
    `min-height: ${gridHeight};`
  }

  ${({ justify }) => justiftOfItems(justify)};
`;

const Col = styled.div<GridProps>`
  flex: 1 1 0px;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  box-sizing: border-box;

  ${({ alignItems }) =>
    // eslint-disable-next-line operator-linebreak
    alignItems &&
    `
    align-items: center
  `};
  ${({ gap }) => 
    `
      padding-left: ${gap}rem;
      padding-left: ${gap}rem;
    `
  }

  ${({ gridHeight }) =>
    gridHeight &&
    `min-height: ${gridHeight};`
  }

  ${({ justify }) => justiftOfItems(justify)};

  > .row {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem;
  }

  ${({ sm }) => (sm ? sizes.sm[sm] : '')}

  @media screen and (min-width: 40em) {
    ${({ md }) => (md ? sizes.sm[md] : '')}
  }

  @media screen and (min-width: 64em) {
    ${({ lg }) => (lg ? sizes.sm[lg] : '')}
  }
`;

export { Row, Col };
