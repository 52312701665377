import { Auth } from 'aws-amplify'
import config from '../config';

export const refreshToken = async () => {
    console.log("token refershing")
    await Auth.currentSession()
    .then((session: any) => {
        // const dispatch = useDispatch();
        // dispatch(saveLoginData(session));
        console.log("session cehcked", session);

      //   if(axios.defaults.headers && axios.defaults.headers.common) {
      //     axios.defaults.headers.common['Authorization'] = session?.accessToken?.jwtToken
      // }
      // axios.interceptors.request.use((config: any) => {
      //   config.headers.Authorization = session?.accessToken?.jwtToken;
      // })
      return { authenticated: true, session: session, }
    })
    .catch(e => {
      Auth.federatedSignIn({ customProvider: config.Auth.provider })
      return { authenticated: false }
    })
}