import * as React from 'react';

import Wallace from '../Wallace';
import { Row, Col } from './styles';
import type { GridProps } from './types';

const Grid: React.FC<GridProps> = (props) => {
  const {
    alignItems,
    children,
    column,
    expanded,
    justify,
    lg,
    md,
    row,
    sm,
    gap,
    gridHeight
  } = props;

  const isRow: boolean = row || !column;

  const Component: React.FC<GridProps> = isRow ? Row : Col;

  return (
    <Wallace>
      <Component
        justify={justify}
        alignItems={alignItems}
        expanded={expanded}
        sm={sm}
        md={md}
        lg={lg}
        gap={gap}
        gridHeight={gridHeight}
      >
        {children}
      </Component>
    </Wallace>
  );
};

export default Grid;
