import styled from "styled-components";

const UserTab = styled.button`
  height: 60px;
  background-color: #24ad28;
  border: 1px solid #b3c5d6;
//   flex-basis: 12%;
  padding: 7px 20px;
  cursor: pointer;
  color: #ffffff;
  font-size:15px;
  font-weight:bold;
  display:flex;
  align-items: center;
  margin-left:2px;
`;

const UserDiv = styled.div`
    display:flex;
`;

const InputTextImgDiv = styled.div`
    // position : relative;
    // top:9px;
    // left:33px;
    padding: 5px 7px;
    min-width: 200px;
    border: 1px solid #000;
    display: flex;
    align-items: center;
`;

const InputTextField = styled.input`
    width:180px;
    font-size:18px;
    text-align:center;
    font-weight:bold;
`;

const UserMainDiv = styled.div`
    display :flex;
    justify-content:space-around;
`;

const InventoryTable = styled.table `
    border-collapse: collapse;
    width: 100%;
`;

const InventoryThead = styled.thead `
   background-color : #b9d7f7;
   color : #0061bb;
`;

const InventoryTh = styled.th `
   border: 1px solid #dddddd;
  text-align: left;
  padding: 15px;
  text-align:center;
`;

const InventoryTd = styled.td `
   border: 1px solid #dddddd;
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left:0px;
  padding-right:0px;
  text-align:center;
`;
const InventoryTr = styled.tr`
   
`;

const InventoryTrhead = styled.tr`
  
`;

const InventoryTbody = styled.tbody `
   
`;
const DownloadCSVDiv = styled.div`
    margin-top: 20px;
    display: flex;
    padding-left: 70px;
    font-weight: bold;
    font-size: 17px;
    cursor: pointer;
`;

const UserStatusSelectList = styled.select`
  border: 1px solid black;
  padding: 0 1em 0 0;
  margin: 0;
  width:200px;
  font-size: inherit;
  font-weight: bold;
  // height:50px;
  text-align:center;
  margin-left:5px;
`;

const InputFieldCheckBox = styled.input`
    width:25px;
    height:25px;
    border : 1px solid #8080801c;
`;

export { UserTab , UserDiv ,InputTextImgDiv , InputTextField , UserMainDiv ,InventoryTable , InventoryTh , InventoryTd , InventoryTr , InventoryThead  , InventoryTbody ,InventoryTrhead ,DownloadCSVDiv , UserStatusSelectList ,InputFieldCheckBox};