import apirequest from '../../apirequest';
import config from '../../config';
import { db } from '../../db';

export const Insertofflinerequest = (offlinebody: any) => {
  let insertresult = new Promise((resolve, reject) => {
    let body = offlinebody.body
    let apipath = offlinebody.apipath
    let httpmethod = offlinebody.httpmethod
    let dxdata = { body, apipath, httpmethod }
    db.pending_requests.add(dxdata).then(() => {
      resolve('success')
    }).catch((error) => {
      reject(error)
    })
  })
  return insertresult;
}

export const Retryofflinerequest = async () => {
  let httprequests: any[] = []
  await db.pending_requests.each(req => httprequests.push(req)).then(async () => {
    let sortedRequest = httprequests.sort((a, b) => (a.id < b.id ? -1 : 1))
    for (const req of sortedRequest) {
      await apirequest(config.restBaseUrl + '/' + req.apipath, req.body).then(async (res: any) => {
        if (res.status == 200) {
          // deleteRecord(req.id)
          await db.pending_requests.where('id').equals(req.id).delete().then(() => {
          })
        }
      })
    }
  })
}


