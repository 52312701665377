import Dexie,{Table} from 'dexie';

export interface Requests{
    id?:number,
    body:string,
    apipath:string,
    httpmethod:string
}

export interface MyInventory{
    totenumber:any,
    assetserial:any,
    statuscode:any,
    statusdesc:any,
    reasoncode:any,
    reasondesc:any
    skucode:any,
    skudesc:any,
    timecreated:string,
    timemodified:Date,
    showage:boolean
}

export interface Reason{
    id?:number,
    code:string,
    description:string,
    timemodified:string
}

export class VSMdexie extends Dexie{
    pending_requests!: Table<Requests>;
    myinventory!: Table<MyInventory>;
    reasoncode!:Table<Reason>;

    constructor(){
        super('STV')
        this.version(1).stores({
            pending_requests:'++id,body,apipath,httpmethod',
            myinventory:'++id,totenumber,assetserial,statuscode,statusdesc,reasoncode,reasondesc,skucode,skudesc,timecreated,timemodified,showage',
            reasoncode:'++id,code,description,timemodified'
        })
    }
}

export const db = new VSMdexie();