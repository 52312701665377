import styled from "styled-components";
import { ImageProps } from "./types";
import { TileBlockProps } from "./types";

const TileWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TileBlock = styled.div<TileBlockProps>`
  width: 100%;
  background-color: #0061bb;
  border-radius: 5px;
  ${({ bgColor }) =>
    bgColor &&
    `
    background-color: ${bgColor};
    border: 6px solid ${bgColor};
  `}
  margin-bottom: 10px;
  text-align: center;
  padding: 30px 0;
  cursor: pointer;
  &:hover,
  &:focus {
    border: 6px solid #4f9e30;
  }

  @media (max-width: 450px) {
    display: flex;
    flex-direction: row;
    padding: 10px;
  }
`;

const IconBlock = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const WhiteText = styled.div`
  color: #ffffff;
  font-size: 20px;
  margin: auto;

  @media (max-width: 900px) {
    font-size: 15px;
  }
`;

const Icon = styled.img<ImageProps>`
  height: ${(props) => props.height};
  width: ${(props) => props.width};

  @media (max-width: 900px) {
    height: 60px;
    width: 60px;
  }
`;

export { TileBlock, TileWrapper, Icon, IconBlock, WhiteText };
