import styled from "styled-components";
import { WrapperProps } from "./types";

const BlockRow = styled.div`
  // border: 1px dashed red;
  // padding: 20px;
  width: 100%;
  margin: auto;
  height: 50px;
`;

const BlockColumn = styled.div`
  width: 50%;
  float: left;
  margin: 10px auto;
  display: flex;
  align-item: center;
  // border: 1px solid red;
  padding: 10px;
  height: 550px;

  @media (max-width: 450px) {
    width: 100%;
    padding: 0;
    height: unset;
  }

  @media (min-width: 450px) {
    height: 500px;
  }
`;

const ScanBlock = styled.div`
  background-color: #333f50;
  width: 100%;
  color: #ffffff;
  // border: 2px solid red;
  height: 100%;
  padding: 10px;
`;

const ScanTitle = styled.div`
  font-size: 27px;
  // float:left;

  @media (max-width: 450px) {
    font-size: 20px;
  }
`;

const ScanDescription = styled.div`
  font-size: 19px;
  // float:left;

  @media (max-width: 450px) {
    font-size: 15px;
  }
`;

const ScannerContainer = styled.div`
  border: 2px dashed #000000;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // min-height: 300px;
  height: 100%;
  padding-top: 50px;
  width: 100%;

  @media (max-width: 450px) {
    padding: 10px;
  }
`;

const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  ${({ fullHeight }) =>
    fullHeight &&
    `
            height: 100%;
        `}
`;
const ScannerImage = styled.img`
  height: 35px;
  position: relative;
  left: 0px;
  // bottom: 20px;
  float: left;
`;

const ScanBorder = styled.div`
  border-top: 2px dashed #5b9bd5;
  min-height: 100px;
  margin-top: 10px;
  padding: 10px;
  // float: bottom;
  height: 30%;

  @media (max-width: 450px) {
    margin-top: 5px;
    border-top: 0px dashed #5b9bd5;
    border-bottom: 2px dashed #5b9bd5;
    margin-bottom: 10px;
  }
`;

const SuccessImage = styled.img`
  // height: 100px;
  // position: relative;
  top: 20px;
`;

const ScanTitleInfo = styled.div`
  height: 70%;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ListItem = styled.li`
  text-align: initial;
  font-size: 18px;

  @media (max-width: 450px) {
    font-size: 10px;
  }
`;

const SuccessMsg = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  // position: relative;
  // top : 50px;
  background-color: #229e27;
`;
const WarningMessage = styled.div`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
  padding: 10px;
  // position: relative;
  // top : 20px;
  background-color: #ff0000;
`;

const SubListItem = styled.li`
  text-align: initial;
  margin-left: 30px;
  font-size: 22px;

  @media (max-width: 450px) {
    font-size: 10px;
  }
`;

const ScannerImageTwo = styled.img`
  height: 90px;
  position: relative;
  left: 0px;
  // bottom: 20px;
  float: left;
  top: 50px;
  filter: blur(2px);
`;

export {
  BlockRow,
  BlockColumn,
  ScanBlock,
  ScanDescription,
  ScanTitle,
  ScannerContainer,
  Wrapper,
  ScannerImage,
  ScanBorder,
  SuccessImage,
  ScanTitleInfo,
  ListItem,
  SuccessMsg,
  WarningMessage,
  SubListItem,
  ScannerImageTwo,
};
