import styled from "styled-components";

const CameraBox = styled.div`
   {
    margin: auto;
    width: 70%;
    padding: 10px;

    @media (max-width: 450px) {
      width: 100%;
    }

    #scan-header {
      margin-bottom: 10px;
    }

    #camera-div {
      height: 400px;
      width: 100%;

      @media (max-width: 350px) {
        height: 300px;
      }
    }

    #camera-window {
      position: relative;
      float: left;
      border: 2px solid #f44336;
      border-radius: 8px;
      width: 100%;
      height: 100%;
      object-fit: fill;
    }

    #switch-camera {
      margin: 10px;
      position: absolute;
      float: left;
      display: none;
      padding: 7px;
      height: 45px;
      width: 45px;
      cursor: pointer;
      border-radius: 7px;
      background-color: grey;
      opacity: 0.7;

      @media (max-width: 450px) {
        height: 40px;
        width: 40px;
      }
    }

    #switch-camera:active {
      opacity: 1;
    }

    span {
      font-size: 12px;
    }

    #button-div {
      display: flex;
      margin: auto;
      justify-content: center;
    }

    #close-camera {
      background-color: #f44336;
      color: white;
      padding: 5px;
      width: 80px;
      height: 35px;
      margin-top: 15px;
      text-align: center;
      font-size: 17px;
      transition-duration: 0.4s;
      cursor: pointer;
      border: 2px solid grey;
      border-radius: 5px;
    }
  }

  .motion-window {
    position: relative;
    height: 100%;
  }

  #scanner-red-bar {
    display: none;
    margin-left: 30px;
    margin-right: 30px;
    height: 3px;
    background-color: red;
    opacity: 0.5;
    position: relative;
    box-shadow: 0px 0px 10px 30px rgba(170, 11, 23, 0.49);
    top: 50%;
    animation-name: scan;
    animation-duration: 4s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-play-state: running;
  }

  #scanner-canvas {
    display:none;
    width:100%;
    margin:auto;
  }

  @keyframes scan {
    0% {
      box-shadow: 0px 0px 10px 30px rgba(170, 11, 23, 0.49);
      top: 50%;
    }
    25% {
      box-shadow: 0px 30px 10px 30px rgba(170, 11, 23, 0.49);
      top: 5px;
    }
    75% {
      box-shadow: 0px -30px 10px 30px rgba(170, 11, 23, 0.49);
      top: 98%;
    }
  }
`;

export { CameraBox };
