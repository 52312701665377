export const SAVE_LOGIN_INFO = "SAVE_LOGIN_INFO";

export const SET_NETWORK_STATE = "SET_NETWORK_STATE";

export const SET_ENGINEER_ACCESS = "SET_ENGINEER_ACCESS";


export const saveLoginData = (loginData: any) => {
    return {
        type: SAVE_LOGIN_INFO,
        payload: loginData
    }
}

export const setNetworkState = (isOnline: boolean) => {
    return {
        type: SET_NETWORK_STATE,
        payload: isOnline
    }
}

export const engineerAccessAction = (hasAccess: boolean) => {
    return {
        type: SET_ENGINEER_ACCESS,
        payload: hasAccess
    }
}