import styled from 'styled-components';
import { ButtonStyle } from './types';

const Btn = styled.button<ButtonStyle>`
  position: relative;
  font-size: 15px;

  font-style: normal;
  font-weight: bold;

  text-align: center;
  // padding: 0.7em 1.2em;

  cursor: pointer;
  user-select: none;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  height: 50px;
  line-height: 30px;
  min-width: 90px;

  border: 2px solid #137cbd80;
  outline: none;

  // background: #6dde71;
  border-radius: 3px;
  color: #ffffff;

  @media (max-width: 900px){
    font-size: 15px;
    height: 40px;
    min-width: 40px;
    line-height: 15px;

  }

  ${({ bgColor }) =>
    bgColor && `
    background-color: ${bgColor};
  `}

  ${({ textColor }) =>
    textColor && `
  color: ${textColor};
`}

  ${({ borderColor }) =>
    borderColor && `
    border: 1px solid ${borderColor};
  `
  }

  -webkit-transition: background 0.2s ease;
  -moz-transition: background 0.2s ease;
  -o-transition: background 0.2s ease;
  transition: background 0.2s ease;

  &:focus,
  &:hover {
    background: #0e7d11;
  }

  &:active {
    background: #0e7d11;
  }

  &:disabled {
    background: linear-gradient(
      0deg,
      rgba(19, 124, 189, 0.5),
      rgba(19, 124, 189, 0.5)
    );
    background : #6dde71;
    // opacity: 0.6;
    cursor: not-allowed;
    pointer-events: all !important;
  }
`;

export default Btn;
