import React from 'react';
import { CardContainer } from './style';
import { CardProps } from './types';
import Wallace from '../Wallace';

const Card: React.FC<CardProps> = (props) => {
	const { children } = props;
	return(
		<Wallace>
			<CardContainer>
				{children}
			</CardContainer>
		</Wallace>
	)
};

export default Card;
